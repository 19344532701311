
.report_content_button {
    padding: 3px 2px;
    font-size: 1rem;
    background-color: transparent;
    color: #a3a3a3;
    text-transform: uppercase;
    border: 0px;
    line-height: 2rem;
    letter-spacing: 0.1em;
}
